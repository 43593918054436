
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { useRoute } from "vue-router";
import { tap } from "lodash";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          createTime: "",
          creatorId: "",
          updateTime: "",
          editorId: "",
          isDelete: false,
          deleteBy: "",
          deleteTime: "",
          mappingType: "",
          mappingDate: "",
          batchId: "",
          purchaseId: "",
          merchantId: "",
          creatorName: "",
          totalMapping: 0,
          _id: "",
        },
      ],
      resume: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        isDelete: false,
        deleteBy: "",
        deleteTime: "",
        purchaseDate: "",
        merchantId: "",
        totalCard: 0,
        totalCardFailed: 0,
        blockedMapping: false,
        _id: "",
        merchantName: "",
        startMapping: "",
        lastMapping: "",
        totalCardActive: 0,
        totalCardSuccess: 0,
        totalCardRemains: 0,
      },
      listCategory: [],
      listStatus: [],
      showModal: false,
      currentPage: 1,
      itemsPerPage: 10,

      showModal2: false,
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      dateNow: moment().format("DD-MM-YYYY HH:mm"),
      purchaseId: "",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      uploadData: "",
      batchId: "",
      dataRes: {
        valid: "",
        unValid: "",
        total: "",
        unValidData: [{
          name: "",
          note: "",
        }],
      },
      tap: {
        batchId: "",
        mappingDate: "",
        purchaseId: "",
        nfcId: "",
      },

      listKartu: [
        {
          id: 0,
          nfcId: "",
        },
      ],

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      categoryId: "",
      nfc: "",
      role: "",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    const route = useRoute();
    this.purchaseId = "" + route.params.id;
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
    this.getBatchId();
    this.getResume();
    // this.getListKategori();
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.listKartu.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.listKartu.length / this.itemsPerPage);
    }
  },

  methods: {
    getBatchId() {
      const toast = useToast();
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" + office + "get_batch_id"
      )
        .then((resp) => {
          console.log(resp.data);
          this.batchId = resp.data;

          this.getData(this.paging.size, this.paging.page, resp.data);
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    },

    tapAdd(id) {
      const route = useRoute();
      var body = {
        batchId: this.batchId,
        mappingDate: moment().format("DD-MM-YYYY"),
        purchaseId: route.params.id,
        nfcId: id,
      };
    },

    handlefileexcel(event) {
      const toast = useToast();
      const val = event.target.files[0].name;
      if (event.target.files[0]) {
        const myArray = val.split(".");
        if (myArray[1] == "xlsx" || myArray[1] == "xls") {
          this.uploadData = event.target.files[0];
        } else {
          return toast.error("File Hanya .xlsx");
        }
      } else {
        toast.error("File Kosong");
      }
    },

    uploadDataExcel() {
      const toast = useToast();
      let formData = new FormData();
      formData.append("uploadedFile", this.uploadData);
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      if (this.uploadData == "") {
        return toast.error("Pilih File Terlebih dahulu!");
      }
      this.isLoading = true
      ApiService.postWithData(
        `crmv2/main_card/card_stock/${office}mapping/upload?batchId=${this.batchId
        }&mappingDate=${moment().format("YYYY-MM-DD")}&purchaseId=${this.purchaseId
        }`,
        formData
      )
        .then((res) => {
          // window.location.reload();
          this.dataRes = res.data;
          ApiService.getWithoutSlug(
            `crmv2/main_card/card_stock/${office}stock/all?page=${this.paging.page}&size=${this.paging.size}&sort_by=nama&sort_dir=-1&batchId=${this.batchId}`
          )
            .then(({ data }) => {
              var a = [
                {
                  id: 0,
                  nfcId: "",
                },
              ];
              data.content.forEach((el, id) => {
                const addId = Math.floor(Math.random() * 100);
                a.push({
                  id: id + 1,
                  nfcId: el.nfcId
                });
                a.sort((a, b) => a.id - b.id);
              });
              this.listKartu = a;
              this.isLoading = false;
              //   this.paging.page = data.page;
              //   this.paging.size = data.size;
              //   this.paging.totalElements = data.totalElements;
              //   this.paging.totalPages = data.totalPages;
              //   this.isLoading = false;
            })
            .catch(({ response }) => {
              this.isLoading = false;
              toast.error(response.response.data.detail);
            });
          this.isLoading = false;
          this.showModal2 = true;
          this.showModal = false;
        })
        .catch((e) => {
          this.isLoading = false;
          // console.log();

          toast.error(e.response.data.detail);
        });
    },

    chooseFiles() {
      var fileInputElement = this.$refs.file;
    },
    unduhTemplate() {
      window.open(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vQc5Qjx8COJH0xhvcd2w-2dTA0hsJ04JFLUs8kjUBgrBSLdZcAX5_v5uyJvnDinQQ/pub?output=xlsx",
        "_blank"
      );
    },

    addCard(nfc) {

      this.isLoading = true;
      var route = useRoute();
      var toast = useToast();
      nfc = nfc.length == 8 ? "0" + nfc : nfc;

      var body = {
        batchId: this.batchId,
        mappingDate: moment().format("YYYY-MM-DD"),
        purchaseId: this.purchaseId,
        nfcId: nfc,
      };
      console.log(nfc.length);
      if (nfc.length > 15) {

        this.isLoading = false;
        return toast.error("Panjang NFC Id Max 15 Karakter!");
      }
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.postWithData(
        "crmv2/main_card/card_stock/" + office + "mapping/tap",
        body
      )
        .then((resp) => {
          ApiService.getWithoutSlug(
            `crmv2/main_card/card_stock/${office}stock/all?page=${this.paging.page}&size=${this.paging.size}&sort_by=nama&sort_dir=-1&batchId=${this.batchId}`
          )
            .then(({ data }) => {
              var a = [
                {
                  id: 0,
                  nfcId: "",
                },
              ];
              data.content.forEach((el, id) => {
                const addId = Math.floor(Math.random() * 100);
                a.push({
                  id: id + 1,
                  nfcId: el.nfcId
                });
                a.sort((a, b) => a.id - b.id);
              });
              console.log(a);
              this.listKartu = a;
              // this.isLoading = false;
              //   this.paging.page = data.page;
              //   this.paging.size = data.size;
              //   this.paging.totalElements = data.totalElements;
              //   this.paging.totalPages = data.totalPages;
              toast.success("Berhasil Menambah NFC ID");
              this.isLoading = false;
            })
            .catch(({ response }) => {
              this.isLoading = false;
              toast.error(response.response.data.detail);
            });
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          toast.error(e.response.data.detail);
        });
      this.nfc = "";
    },

    getData(size, page, batch) {
      const route = useRoute();
      var itemId = route.params.id;
      var toast = useToast();

      console.log(batch, "dfds");
      this.isLoading = true;

      let search = "";
      let valcategoryId = "";
      if (this.categoryId) {
        valcategoryId = this.categoryId;
      }
      if (this.name) {
        search = this.name;
      }

      if ((this.batchId = "")) {
        this.batchId = batch;
      }

      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        `crmv2/main_card/card_stock/${office}stock/all?page=${page}&size=${size}&sort_by=nama&sort_dir=-1&batchId=${this.batchId}`
      )
        .then(({ data }) => {
          var a = [
            {
              id: 0,
              nfcId: "",
            },
          ];
          data.content.forEach((el, id) => {
            const addId = Math.floor(Math.random() * 100);
            a.push({
              id: id + 1,
              nfcId: el.nfcId
            });
            a.sort((a, b) => a.id - b.id);
          });
          console.log(a);
          this.listKartu = a;
          this.isLoading = false;
          //   this.paging.page = data.page;
          //   this.paging.size = data.size;
          //   this.paging.totalElements = data.totalElements;
          //   this.paging.totalPages = data.totalPages;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          toast.error(response.response.data.detail);
        });
    },
    getResume() {
      var toast = useToast();
      const route = useRoute();
      var itemId = route.params.id;
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_purchasing/" + office + itemId
      )
        .then((resp) => {
          this.resume = resp.data;
          this.resume.purchaseDate = moment(this.resume.purchaseDate).format(
            "DD-MM-YYYY"
          );
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
          toast.error(response.response.data.detail);
        });
    },
    getListKategori() {
      ApiService.getWithoutSlug("crmv2/main_order/product/combo_catagory").then(
        ({ data }) => {
          this.listCategory = data;
          localStorage.setItem("listCategory", JSON.stringify(data));
        }
      );
    },

    backModal() {
      (this.showModal2 = false),
        (this.uploadData = ""),
        (this.dataRes = {
          valid: "",
          unValid: "",
          total: "",
          unValidData: [],
        });
    },
    pilihKategori(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page, this.batchId);
    },

    async handleDelete(item) {
      const toast = useToast();
      var office = "";
      if (this.role == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      await ApiService.delete(
        `crmv2/main_card/card_stock/${office}mapping/${item}?batchId=${this.batchId}`
      )
        .then((res) => {
          this.listKartu = this.listKartu.filter(
            (person) => person.nfcId != item
          );

          toast.success("Kartu berhasil dihapus ");
        })
        .catch((errornih) => {
          console.log(errornih.response);
          toast.error(errornih.response.data.detail);
        });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;

      // ApiService.delete(
      //   "crmv2/main_order/product/" + item._id
      // )
      //   .then((res) => {
      //     toast.success("Hapus Berhasil");

      //     this.getData(this.paging.size, this.paging.page, this.batchId);
      //     this.isLoading = false;
      //   })

      //   .catch((error) => {
      //     toast.error("Gagal Hapus");
      //     this.isLoading = false;
      //   });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page, this.batchId);
    },
    // nextPage() {
    //   const page = this.paging.page + 1;
    //   this.getData(this.paging.size, page, this.batchId);
    // },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page, this.batchId);
    },
    changePageSize() {
      const page = this.paging.page;
      this.getData(this.paging.size, page, this.batchId);
    },

    changeModal() { },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD h:mm");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
